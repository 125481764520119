import React from 'react';
import './../styles/styles.css'
import { Todo } from '../models/model';
import SingleTodo from './SingleTodo';

interface Props{
    todos: Todo[];
    setTodos: React.Dispatch<React.SetStateAction<Todo[]>>;
   // handleAdd: (e: React.FormEvent) => void;
}

const TodoList: React.FC<Props> = ({todos,setTodos}:Props) => {
  return (
    <div className='todos'>

        {todos.map((t) => 
        (
          //<li>{t.todo + " " + t.id}</li>
          <SingleTodo todo={t} key={t.id} todos={todos} 
            setTodos={setTodos}
          />
        )
      )}
    </div>
  )
}

export default TodoList