import React, { useState } from 'react';
import './styles/App.css';
import InputField from './component/InputField';
import { Todo } from './models/model';
import TodoList from './component/TodoList';

const App: React.FC = () => {
  const [todo, setTodo] = useState<string>("");
  const [todos, setTodos] = useState<Todo[]>([]);

  //console.log(todo);

  const handleAdd = (e: React.FormEvent)  => {
      e.preventDefault();

      if(todo)
      {
        setTodos([...todos,{id:Date.now(), todo:todo, isDone: false}]);
        setTodo("");    // clear the textbox 
      }
  };

  console.log(todos);


  return (
    <div className="App">
      <span className="heading">
        Todo List
       </span>

      <InputField todo={todo} setTodo={setTodo} handleAdd={handleAdd} />
      <TodoList todos={todos} setTodos={setTodos}/>
      
      
    </div>
  );
}

export default App;
