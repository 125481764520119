import React, { useRef, useEffect } from "react";
import './../styles/styles.css'

interface Props{
    todo: string;
    setTodo: React.Dispatch<React.SetStateAction<string>>;
    handleAdd: (e: React.FormEvent) => void;
}

const InputField = ({todo, setTodo, handleAdd}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    
    useEffect( () => {
        inputRef.current?.focus();
    }, [todo])

    return <form className='input' onSubmit={(e) => 
        {
        handleAdd(e)
        inputRef.current?.blur(); 
        }    
    }>
        <input type='text' 
        ref={inputRef}
        value={todo} 
        onChange={
            (e) =>setTodo(e.target.value)
        } 
        placeholder="Enter text here" className='input__box'></input>
        <button className='input_submit' type='submit'>Go</button>
    </form >;
}

export default InputField